<template>
  <div class="flex flex-col h-screen items-center justify-center bg-gray-950 text-white p-4">
    <img width="250px" src="../assets/img/colorize_design_logo.svg" alt="colorize.design" class="mx-auto mb-12"/>
    <div class="bg-gray-950 border border-gray-700 rounded-lg shadow-xl p-8 w-full max-w-md mx-auto">
      <h1 v-if="authMode === 'login'" class="text-2xl font-semibold text-center mb-4">Login</h1>
      <h1 v-if="authMode === 'register'" class="text-2xl font-semibold text-center mb-4">Register</h1>
      
      <!-- Login Form -->
      <form v-if="authMode === 'login'" @submit.prevent="handleEmailLogin" class="space-y-4">
        <div>
          <label for="email" class="block text-sm font-medium mb-1">Email</label>
          <input 
            id="email" 
            v-model="email" 
            type="email" 
            required 
            class="w-full border-0 rounded px-4 py-2 ring-gray-700 ring-1 ring-inset focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600 bg-gray-900"
            :class="{ 'ring-red-700': errorMessage!='', 'focus-within:ring-red-700': errorMessage!=''}"
            placeholder="your@email.com"
          />
        </div>
        
        <div>
          <div class="flex justify-between items-center mb-1">
            <label for="password" class="block text-sm font-medium">Password</label>
            <button 
              type="button" 
              @click="authMode = 'reset'" 
              class="text-xs text-indigo-500 hover:text-indigo-400"
            >
              Forgot password?
            </button>
          </div>
          <input 
            id="password" 
            v-model="password" 
            type="password" 
            required 
            class="w-full border-0 rounded px-4 py-2 ring-gray-700 ring-1 ring-inset focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600 bg-gray-900"
            :class="{ 'ring-red-700': errorMessage!='', 'focus-within:ring-red-700': errorMessage!=''}"
            placeholder="••••••••"
          />
        </div>
        
        <div v-if="errorMessage" class="text-red-600 text-sm py-2">{{ errorMessage }}</div>
        
        <button 
          type="submit" 
          class="w-full gap-1 transition-all duration-300 mt-2 rounded-md 
                           bg-gradient-to-r from-violet-900 to-indigo-600 
                           hover:from-violet-800 hover:to-indigo-500 
                           px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm"
          :disabled="isLoading"
        >
          {{ isLoading ? 'Signing in...' : 'Sign in' }}
        </button>
      </form>
      
      <!-- Register Form -->
      <form v-if="authMode === 'register'" @submit.prevent="handleEmailRegister" class="space-y-4">
        <div>
          <label for="name" class="block text-sm font-medium mb-1">Full Name</label>
          <input 
            id="name" 
            v-model="name" 
            type="text" 
            required 
            class="w-full border-0 rounded px-4 py-2 ring-gray-700 ring-1 ring-inset focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600 bg-gray-900"
            :class="{ 'ring-red-700': errorMessage!='', 'focus-within:ring-red-700': errorMessage!=''}"
            placeholder="John Doe"
          />
        </div>
        
        <div>
          <label for="register-email" class="block text-sm font-medium mb-1">Email</label>
          <input 
            id="register-email" 
            v-model="email" 
            type="email" 
            required 
            class="w-full border-0 rounded px-4 py-2 ring-gray-700 ring-1 ring-inset focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600 bg-gray-900"
            :class="{ 'ring-red-700': errorMessage!='', 'focus-within:ring-red-700': errorMessage!=''}"
            placeholder="your@email.com"
          />
        </div>
        
        <div>
          <label for="register-password" class="block text-sm font-medium mb-1">Password</label>
          <input 
            id="register-password" 
            v-model="password" 
            type="password" 
            required 
            class="w-full border-0 rounded px-4 py-2 ring-gray-700 ring-1 ring-inset focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600 bg-gray-900"
            :class="{ 'ring-red-700': errorMessage!='', 'focus-within:ring-red-700': errorMessage!=''}"
            placeholder="••••••••"
          />
        </div>
        
        <div v-if="errorMessage" class="text-red-400 text-sm py-2">{{ errorMessage }}</div>
        
        <button 
          type="submit" 
          class="w-full gap-1 transition-all duration-300 mt-2 rounded-md 
                           bg-gradient-to-r from-violet-900 to-indigo-600 
                           hover:from-violet-800 hover:to-indigo-500 
                           px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm"
          :disabled="isLoading"
        >
          {{ isLoading ? 'Creating account...' : 'Create account' }}
        </button>
      </form>
      
      <!-- Email Confirmation Screen -->
      <div v-if="authMode === 'confirmation'" class="text-center space-y-6">
        <div class="flex justify-center mb-4 mt-10">
          <div class="bg-gray-800 p-4 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
            </svg>
          </div>
        </div>
        <h2 class="text-xl font-bold">Check your email</h2>
        <p class="text-gray-400">We've sent a confirmation link to:</p>
        <p class="font-medium text-indigo-500">{{ email }}</p>
        <p class="text-gray-400 text-sm">Click the link in the email to verify your account. If you don’t see it in your inbox, check your spam folder.</p>
        
        <div class="pt-4">
          <button 
            @click="authMode = 'login'" 
            class="text-indigo-500 hover:text-indigo-400 text-sm"
          >
            Back to login
          </button>
        </div>
      </div>
      
      <!-- Password Reset Form -->
      <form v-if="authMode === 'reset'" @submit.prevent="handlePasswordReset" class="space-y-4">
        <button 
            @click="authMode = 'login'" 
            class="text-indigo-500 hover:text-indigo-400 text-sm flex items-center gap-1"
          >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
            <path fill-rule="evenodd" d="M14 8a.75.75 0 0 1-.75.75H4.56l1.22 1.22a.75.75 0 1 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1 0-1.06l2.5-2.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8Z" clip-rule="evenodd" />
          </svg>
            Back
          </button>
        <div class="flex justify-center mb-4 mt-10">
          <div class="bg-gray-800 p-4 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
            </svg>
          </div>
        </div>
        
        <h2 class="text-lg font-semibold text-center">Reset your password</h2>
        <p class="text-gray-400 text-sm text-center mb-4">Enter your email and we'll send you a link to reset your password</p>
        
        <div>
          <label for="reset-email" class="block text-sm font-medium mb-1">Email</label>
          <input 
            id="reset-email" 
            v-model="email" 
            type="email" 
            required 
            class="w-full border-0 rounded px-4 py-2 ring-gray-700 ring-1 ring-inset focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600 bg-gray-900"
            :class="{ 'ring-red-700': errorMessage!='', 'focus-within:ring-red-700': errorMessage!=''}"
            placeholder="your@email.com"
          />
        </div>
        
        <div v-if="errorMessage" class="text-red-400 text-sm py-2">{{ errorMessage }}</div>
        
        <button 
          type="submit" 
          class="w-full gap-1 transition-all duration-300 mt-2 rounded-md 
                           bg-gradient-to-r from-violet-900 to-indigo-600 
                           hover:from-violet-800 hover:to-indigo-500 
                           px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm"
          :disabled="isLoading"
        >
          {{ isLoading ? 'Sending...' : 'Send reset link' }}
        </button>
      </form>
      
      <!-- Password Reset Confirmation -->
      <div v-if="authMode === 'resetConfirmation'" class="text-center space-y-6">
        <div class="flex justify-center mb-4 mt-10">
          <div class="bg-gray-800 p-4 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
            </svg>
          </div>
        </div>
        <h2 class="text-xl font-bold">Check your email</h2>
        <p class="text-gray-400">We've sent a password reset link to:</p>
        <p class="font-medium text-blue-400">{{ email }}</p>
        <p class="text-gray-400 text-sm">Click the link in the email to reset your password.</p>
        
        <div class="pt-4">
          <button 
            @click="authMode = 'login'" 
            class="text-indigo-500 hover:text-indigo-400 text-sm"
          >
            Back to login
          </button>
        </div>
      </div>
      
      <!-- Social Login Section -->
      <div v-if="['login', 'register'].includes(authMode)" class="my-6">
        <div class="relative flex items-center justify-center">
          <div class="border-t border-gray-600 w-full"></div>
          <span class="px-3 text-sm text-gray-400">or</span>
          <div class="border-t border-gray-600 w-full"></div>
        </div>
        
        <div class="mt-6">
          <button 
            @click="login" 
            class="w-full flex justify-center items-center bg-gray-900 hover:bg-gray-800 text-gray-800 font-medium py-2 px-4 rounded transition text-white border border-gray-700 text-sm"
          >
          <svg class="mr-1" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_829_635)"><path d="M19.8094 12.1497C19.8094 11.4942 19.7562 11.0158 19.6411 10.5198H12.1558V13.4784H16.5495C16.4609 14.2137 15.9826 15.321 14.9195 16.0651L14.9046 16.1641L17.2714 17.9976L17.4353 18.0139C18.9412 16.6232 19.8094 14.5769 19.8094 12.1497Z" fill="#959AA1"></path><path d="M12.1557 19.945C14.3083 19.945 16.1153 19.2363 17.4353 18.0139L14.9195 16.065C14.2463 16.5345 13.3427 16.8623 12.1557 16.8623C10.0474 16.8623 8.25806 15.4716 7.6202 13.5493L7.5267 13.5573L5.06575 15.4618L5.03357 15.5513C6.34459 18.1556 9.03754 19.945 12.1557 19.945Z" fill="#70757E"></path><path d="M7.62023 13.5494C7.45193 13.0533 7.35453 12.5218 7.35453 11.9726C7.35453 11.4233 7.45193 10.8918 7.61138 10.3958L7.60692 10.2901L5.11514 8.35498L5.03361 8.39376C4.49327 9.47449 4.18323 10.6881 4.18323 11.9726C4.18323 13.257 4.49327 14.4706 5.03361 15.5513L7.62023 13.5494Z" fill="#959AA1"></path><path d="M12.1557 7.08269C13.6527 7.08269 14.6626 7.72934 15.2384 8.26974L17.4884 6.07286C16.1065 4.7884 14.3083 4 12.1557 4C9.03754 4 6.34459 5.78937 5.03357 8.39371L7.61134 10.3957C8.25806 8.47347 10.0474 7.08269 12.1557 7.08269Z" fill="#70757E"></path></g><defs><clipPath id="clip0_829_635"><rect fill="white" height="16" transform="translate(4 4)" width="16"></rect></clipPath></defs></svg>
            <span v-if="authMode === 'login'">Continue with Google</span> 
            <span v-if="authMode === 'register'">Continue with Google</span> 
          </button>
        </div>
      </div>
      
      <!-- Toggle Auth Mode -->
      <div class="text-center mt-4">
        <p v-if="authMode === 'login'" class="text-sm text-gray-400">
          Don't have an account?
          <button @click="authMode = 'register'" class="text-indigo-500 hover:text-indigo-400">
            Sign up
          </button>
        </p>
        <p v-else-if="authMode === 'register'" class="text-sm text-gray-400">
          Already have an account?
          <button @click="authMode = 'login'" class="text-indigo-500 hover:text-indigo-400">
            Sign in
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const supabase = useSupabaseClient();
const router = useRouter();
const user = useSupabaseUser();

// Redirect authenticated users away from the login page
watchEffect(() => {
  if (user.value) {
    router.push('/dashboard');
  }
});

// Form states
const authMode = ref('login'); // 'login', 'register', 'reset', 'confirmation', 'resetConfirmation'
const email = ref('');
const password = ref('');
const name = ref('');
const errorMessage = ref('');
const isLoading = ref(false);

// Google OAuth login
async function login() {
  isLoading.value = true;
  errorMessage.value = '';
  
  try {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: { redirectTo: window.location.origin + '/confirm' },
    });

    if (error) throw error;
  } catch (error) {
    errorMessage.value = error.message || 'Failed to sign in with Google';
  } finally {
    isLoading.value = false;
  }

  try{
    window.clarity("event", "Google OAuth Clicked")
  } catch{
    
  }
}

// Email password login
async function handleEmailLogin() {
  isLoading.value = true;
  errorMessage.value = '';
  
  try {
    const { error } = await supabase.auth.signInWithPassword({
      email: email.value,
      password: password.value,
    });

    if (error) throw error;
    // Successful login will trigger the watchEffect redirect
  } catch (error) {
    errorMessage.value = error.message || 'Invalid email or password';
  } finally {
    isLoading.value = false;
  }

  try{
    window.clarity("event", "Email Login Clicked")
  } catch{
    
  }
}

// Email registration
async function handleEmailRegister() {
  isLoading.value = true;
  errorMessage.value = '';
  
  try {
    const { error } = await supabase.auth.signUp({
      email: email.value,
      password: password.value,
      options: {
        data: {
          display_name: name.value,
        },
        emailRedirectTo: window.location.origin + '/confirm',
      }
    });

    if (error) throw error;
    
    // Switch to confirmation screen
    authMode.value = 'confirmation';
  } catch (error) {
    errorMessage.value = error.message || 'Registration failed';
  } finally {
    isLoading.value = false;
  }

  try{
    window.clarity("event", "Email Register Clicked")
  } catch{
    
  }
}

// Password reset
async function handlePasswordReset() {
  isLoading.value = true;
  errorMessage.value = '';
  
  try {
    const { error } = await supabase.auth.resetPasswordForEmail(email.value, {
      redirectTo: window.location.origin + '/reset-password',
    });

    if (error) throw error;
    
    // Switch to reset confirmation screen
    authMode.value = 'resetConfirmation';
  } catch (error) {
    errorMessage.value = error.message || 'Failed to send reset link';
  } finally {
    isLoading.value = false;
  }

  try{
    window.clarity("event", "Password Reset Clicked")
  } catch{
    
  }
}
</script>